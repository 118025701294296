import React from 'react'
import { Helmet } from 'react-helmet'

export default function UsedVehiclesSEO() {
  return (
    <>
        <Helmet>
            <title>Used Vehicles &#8226; Wheels Repository  </title>
            <meta name="description" content="Browse Used Vehicles" />
            <link rel="canonical" href="https://whrepo.com/used-vehicles" />
        </Helmet>
    </>
  )
}

