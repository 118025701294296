import React from 'react'
import { Helmet } from 'react-helmet'

export default function ResetPasswordSEO() {
  return (
    <>
        <Helmet>
            <title>Reset Password &#8226; Wheels Repository</title>
            <meta name="description" content="Reset Password - whrepo.com" />
            <link rel="canonical" href="https://whrepo.com/reset-password" />
        </Helmet>
    </>
  )
}

