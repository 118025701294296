import React from 'react';
import { NavLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
//import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import InboxIcon from '@material-ui/icons/MoveToInbox';
//import MailIcon from '@material-ui/icons/Mail';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import StarsIcon from '@material-ui/icons/Stars';
import HistoryIcon from '@material-ui/icons/History';
import LockIcon from '@material-ui/icons/Lock';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory';
//import InfoIcon from '@material-ui/icons/Info';
//import TelegramIcon from '@material-ui/icons/Telegram';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

function MobileMenuDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >

      <List>
        <ListItem component={NavLink} to="/" button><ListItemIcon><HomeIcon /></ListItemIcon><ListItemText primary="Home" /></ListItem>
        {/* <ListItem component={NavLink} to="/vehicles" button><ListItemIcon><DirectionsCarIcon /></ListItemIcon><ListItemText primary="All Vehicles" /></ListItem> */}
        <ListItem component={NavLink} to="/used-vehicles" button><ListItemIcon><HistoryIcon /></ListItemIcon><ListItemText primary="Used Vehicles" /></ListItem>
        <ListItem component={NavLink} to="/new-vehicles" button><ListItemIcon><StarsIcon /></ListItemIcon><ListItemText primary="New Vehicles" /></ListItem>
        {/* <ListItem component={NavLink} to="/dealer-signup" button><ListItemIcon><TelegramIcon /></ListItemIcon><ListItemText primary="Dealer Sign Up" /></ListItem> */}
        <ListItem component={NavLink} to="/sell-your-vehicle" button><ListItemIcon><DirectionsCarIcon /></ListItemIcon><ListItemText primary="Sell Vehicle" /></ListItem>
        <ListItem component={NavLink} to="/dealers" button><ListItemIcon><StoreMallDirectoryIcon /></ListItemIcon><ListItemText primary="Dealers" /></ListItem>
        {!props.isAuthenticated?
                
                <>
                <ListItem component={NavLink} to="/register" button><ListItemIcon><CardMembershipIcon /></ListItemIcon><ListItemText primary="Register"/></ListItem>
                <ListItem component={NavLink} to="/login" button><ListItemIcon><LockIcon /></ListItemIcon><ListItemText primary="Login"/></ListItem>
                </>
                :null
                 }
      </List>
      {/* <Divider />
      <List>
        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List> */}
    </div>
  );

  if (isWidthDown('sm', props.width)){
  return (
    <>
      <Button onClick={toggleDrawer('right', true)}><MenuIcon /></Button>
      <SwipeableDrawer
        anchor="right"
        open={state.right}
        onClose={toggleDrawer('right', false)}
        onOpen={toggleDrawer('right', true)}
      >
        {sideList('right')}
      </SwipeableDrawer>
    </>
  );
  }else{
    return null;
  }
}

export default withWidth()(MobileMenuDrawer);