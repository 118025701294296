import React from 'react'
import { Helmet } from 'react-helmet'

export default function LoginSEO() {
  return (
    <>
        <Helmet>
            <title>Login &#8226; Wheels Repository</title>
            <meta name="description" content="Login - whrepo.com" />
            <link rel="canonical" href="https://whrepo.com" />
        </Helmet>
    </>
  )
}

