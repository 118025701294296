import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from '../../assets/jss/components/teaserTabStyle.js'
const useStyles = makeStyles(styles);

function MakesList(props) {
    //console.log(props.makes);
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    return (
        <div className={classes.homeRow}>
            <Typography component="h2" variant="h4" gutterBottom>Browse by Make</Typography>
            {!props.isLoading ? ( 
            <div className={classes.Tabs}>
               
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="makes tabs">
                    <Tab label="Used" {...a11yProps(0)} />
                    <Tab label="New" {...a11yProps(1)} />
                    </Tabs>
                
                <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={2} alignItems="center">
                    {props.usedMakes.map((make)=>{
                        return (
                                <Grid item xs={6} sm={4} md={2} key={make.id}>  
                                <NavLink className={classes.link} to={'/used-vehicles/?make='+make.make_name}>
                                    <Typography component="h3" variant="subtitle1">{make.make_name} <Chip variant="default" size="small" label={make.count} className={classes.chipCount}/></Typography> 
                                </NavLink>
                            </Grid>
                                
                        )
                    })} 
                    </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Grid container spacing={2} alignItems="center">
                    {props.newMakes.map((make)=>{
                        return (
                                <Grid item xs={6} sm={4} md={2} key={make.id}>  
                                <NavLink className={classes.link} to={'/new-vehicles/?make='+make.make_name}>
                                    <Typography component="h3" variant="subtitle1">{make.make_name} <Chip variant="default" size="small" label={make.count} className={classes.chipCount}/></Typography> 
                                </NavLink>
                            </Grid>
                                
                        )
                    })}
                    </Grid>
                </TabPanel>
            </div>
            ) : (
                <Grid item xs={12}>
                    <CircularProgress color="secondary" />
                    <p>Loading...</p>
                </Grid>
              )}
            

        </div>
    )
}
export default MakesList;

function a11yProps(index) {
    return {
      id: `makes-tab-${index}`,
      'aria-controls': `makes-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`makes-tabpanel-${index}`}
        aria-labelledby={`makes-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3} >
            <Typography component="div">{children}</Typography>
          </Box>
        )}
      </div>
    );
  }