import React from 'react'
import { Helmet } from 'react-helmet'

export default function PrivacySEO() {
  return (
    <>
        <Helmet>
            <title>Privacy Policy &#8226; Wheels Repository  </title>
            <meta name="description" content="Privacy Policy - whrepo.com" />
            <link rel="canonical" href="https://whrepo.com/privacy" />
        </Helmet>
    </>
  )
}

