import React from 'react'
import { NavLink, Link as RouterLink} from "react-router-dom"

import LoginSEO from '../../components/SEO/Login'
import * as api from '../../api/customerApi'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Alert from '@material-ui/lab/Alert'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import CancelIcon from '@material-ui/icons/Cancel'

import { makeStyles, ThemeProvider} from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

import useForm from "react-hook-form"
import { RHFInput } from "react-hook-form-input"

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    "& .MuiDivider-root":{
      margin:"20px 0",
    }
  },
  image: {
    backgroundImage: 'url(/images/flat-bg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(5, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  alert:{
    width: '100%',
    marginTop: theme.spacing(1),
  },
  logo:{
    marginBottom: '75px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cancelIcon:{
    float:"right",
  },
  rightLink:{
    float: "right",
  }
}));

export default function Login() {
  const classes = useStyles();
  const [error, setError] = React.useState("");
  const methods = useForm();
  const { handleSubmit, register, setValue, errors } = methods;
  const handleClose = () => {
    setError("");
  }
  const onSubmit = (data) => {
    api.login(data).then((response)=> {
      if(response.data.success){
        if(data.remember){
          window.localStorage.setItem("user", response.data.user);
          window.localStorage.setItem("token", response.data.token);
        }else{
          window.sessionStorage.setItem("user", response.data.user);
          window.sessionStorage.setItem("token", response.data.token);
        }
        document.location='/';
      }else{
        setError(response.data.error);
      }
  })
  }

  return (
    <ThemeProvider theme={whrepoTheme}>
      <LoginSEO />
      <div>
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <IconButton aria-label="delete" component={RouterLink} to='/' className={classes.cancelIcon} title="Go to Home Page"><CancelIcon /></IconButton>
      <div className={classes.paper}>
        <div className={classes.logo}>
            <NavLink to="/"><img src="/images/whrepo-temp-logo.jpg" alt="Whrepo Logo" /></NavLink>
          </div>
          <Typography component="h1" variant="h5">
          Welcome back to Wheels Repository!
          </Typography>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {/* <p>{error}</p>  */}
          {error?<Alert className={classes.alert} variant="filled" severity="error" onClose={handleClose}>{error}</Alert>:""}
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="email"
              label="Email Address"
              type="email"
              name="email"
              autoComplete="email"
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!errors.email}
              inputRef={register({
                required: 'Required',
                pattern: {value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message:'Invalid email address.'},
                max: 191
              })}
            />
            {errors.email && (errors.email.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.email.message}</Typography>:""}

            
            <TextField
                      id="password"
                      margin="normal"
                      label="Password"
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      variant="outlined"
                      fullWidth
                      error={!!errors.password}
                      inputRef={register({
                        required: 'Required'
                      })}
                    />
                    {errors.password && "Required"?<Typography variant="caption" display="block" color="error" align="left">{errors.password && "Required"}</Typography>:""}
            
            <RHFInput
              as={
                
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                
              }
              name="remember"
              register={register}
              setValue={setValue}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Link color="secondary" href="/register" variant="body2">
                  Don't have an account? Sign up
                </Link>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Link color="secondary" href="/forgot-password" variant="body2" className={classes.rightLink}>
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Divider />
              <Typography variant="body2">By continuing you accept our our <Link color="secondary" href="/terms">Terms of Use</Link> and <Link color="secondary" href="/privacy">Privacy Policy</Link>.</Typography>
              
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
    </div>
    </ThemeProvider>
  );
}