import React from 'react'
import { Helmet } from 'react-helmet'

const InventoryDetailSEO = (props) => {
    const vehicle = props.vehicle;  
  return (
    <>
        <Helmet>
            <title>{`${vehicle.title+' '+vehicle.trim}`} &#8226; Wheels Repository  </title>
            <meta name="description" content={`Browse all vehicles listed by ${vehicle.title}`} />
          <link rel="canonical" href={props.url} />
        </Helmet>
    </>
  )
}
export default InventoryDetailSEO;
