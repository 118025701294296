import React from 'react';
import { NavLink } from "react-router-dom";

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import Divider from '@material-ui/core/Divider';

export default function AccountMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //if(props.isAuthenticated){
    return (
    
      <>
        <Button aria-controls="account-menu" aria-haspopup="true" onClick={handleClick}>
          <AccountCircleIcon /> {props.user.first_name +" "+ props.user.last_name}
        </Button>
        <Menu
          id="account-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}><NavLink to="/profile" >{props.authUser.first_name} {props.authUser.last_name}</NavLink></MenuItem> */}
          {/* <Divider /> */}
          <MenuItem onClick={handleClose}><NavLink to="/profile" >Profile</NavLink></MenuItem>
          <MenuItem onClick={handleClose}><NavLink to="/logout" >Logout</NavLink></MenuItem>
        </Menu>
      </>
      
    );
  // }else{
  //   return null;
  // }
  
}