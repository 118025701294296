import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  homeBanner: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: 'url(/images/wheels-repository.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
  homeBannerContent: {
    position: 'relative',
    marginTop: '100px',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
  tabContainer:{ 
    backgroundColor:"rgba(255,255,255,0.9)",
   "& .MuiBox-root":{
      padding:theme.spacing(0,2,2,2),
    }
  },
  homeSearchForm: {
    marginBottom:0,
  },
  formControlSelect: {
   width: "calc(33.33% - 7px)",
   marginRight: theme.spacing(1),
   marginBottom: theme.spacing(1),
   [theme.breakpoints.down('sm')]: {
    width: 100+'%',
    },
  },
  formControlSelectLast:{
    marginRight:0,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth:120
  },
  formLabel:{
      fontSize: "1em",
      fontWeight: "600"
  },
  button:{
    marginTop:theme.spacing(1),
  }
}));

export default function InventorySearchForm(props) {
const classes = useStyles();
const [tabValue, setTabValue] = React.useState(0);
const [values, setValues] = React.useState({
  newYear: '',
  newMake: '',
  newModel:'',
  usedYear: '',
  usedMake: '',
  usedModel:'',
});
const numbers = [0, 1];

const handleTabChange = (event, newValue) => {
  setTabValue(newValue);
};

const handleSelectChange = event => {
  let params = {};
  if(event.target.name === "year"){
      setValues(oldValues => ({
          ...oldValues,
          [event.target.name]: event.target.value,
          }));
      params = {...values,[event.target.name]: event.target.value};
  }else{
      
      let valuePair = event.target.value;
      setValues(oldValues => ({
          ...oldValues,
          [event.target.name]: valuePair,
          }));
      params = {...values,[event.target.name]: valuePair};
  }
  
  props.handleSearch(params);    
};


  return (
    <Paper className={classes.homeBanner} style={{ backgroundImage:'' }}>
      {/* Increase the priority of the hero background image */}
      {<img style={{ display: 'none' }} src="/images/whrepo-sample-banner.jpg" alt="Whrepo Banner" />}
      <div className={classes.overlay} />
      <Grid container>
        <Grid item md={6}>
          <div className={classes.homeBannerContent}>
            <Typography component="h1" variant="h4">Find the best deals now</Typography>
            <Paper className={classes.tabContainer}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="inventory search" textColor="secondary" variant="fullWidth">
                  <Tab label="Used Vehicles" {...a11yProps(0)} />
                  <Tab label="New Vehicles" {...a11yProps(1)} />
                </Tabs>
                {numbers.map((number) =>  
                <TabPanel key={number} value={tabValue} index={number}>
                  <form className={classes.homeSearchForm}>
                      <FormControl component="fieldset" className={classes.formControlSelect}>
                        <InputLabel htmlFor="year">Year</InputLabel>
                        <Select value={values.year!==undefined?values.year:''} onChange={handleSelectChange} inputProps={{name: 'year',id: 'year',}}>
                            <MenuItem value="">Select</MenuItem>
                            {number === 0 ? props.usedYears.map((year)=>{
                                    return <MenuItem key={year.year} value={year.year}>{year.year} ({year.count})</MenuItem>
                                }):props.newYears.map((year)=>{
                                  return <MenuItem key={year.year} value={year.year}>{year.year} ({year.count})</MenuItem>
                              })} 
                        </Select>
                      </FormControl>
                      <FormControl component="fieldset" className={classes.formControlSelect}>
                          <InputLabel htmlFor="make">Make</InputLabel>
                          <Select value={values.make!==undefined?values.make:''} onChange={handleSelectChange} inputProps={{name: 'make',id: 'make',}}>
                              <MenuItem value="">Select</MenuItem>
                              {number === 0 ? props.usedMakes.map((make)=>{
                                  return <MenuItem key={make.id} value={make.make_name}>{make.make_name} ({make.count})</MenuItem>
                              }):props.newMakes.map((make)=>{
                                return <MenuItem key={make.id} value={make.make_name}>{make.make_name} ({make.count})</MenuItem>
                            })}  
                          </Select>
                      </FormControl>
                      <FormControl component="fieldset" className={`${classes.formControlSelect} ${classes.formControlSelectLast}`}>
                          <InputLabel htmlFor="model">Model</InputLabel>
                          <Select value={values.model!==undefined?values.model:''} onChange={handleSelectChange} inputProps={{name: 'model',id: 'model',}}>
                              <MenuItem value="">Select</MenuItem>
                              {number === 0 ? props.usedModels.map((model)=>{
                                  return <MenuItem key={model.id} value={model.model_name}>{model.model_name} ({model.count})</MenuItem>
                              }):props.newModels.map((model)=>{
                                return <MenuItem key={model.id} value={model.model_name}>{model.model_name} ({model.count})</MenuItem>
                            })} 
                          </Select>
                      </FormControl>
                      <Button onClick={e => props.handleSubmit(e, (number === 0)?"used":"new")} variant="contained" size="medium" color="primary" className={classes.button} fullWidth>
                        Search {(number === 0)?"used vehicles":"new vehicles"}
                      </Button>
                  </form>
                </TabPanel>
                )}
            </Paper>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

