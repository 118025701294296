import React from 'react'
import { NavLink, Link as RouterLink} from "react-router-dom"

import RegisterSEO from '../../components/SEO/Register'
import * as api from '../../api/customerApi'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import HowToRegSharpIcon from '@material-ui/icons/HowToRegSharp'
import CancelIcon from '@material-ui/icons/Cancel'
import Alert from '@material-ui/lab/Alert'
import { CircularProgress } from '@material-ui/core'

import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

import useForm from "react-hook-form"

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        "& .MuiDivider-root":{
          margin:"20px 0",
        }
      },
      image: {
        backgroundImage: 'url(/images/flat-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      paper: {
        margin: theme.spacing(5, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      logo:{
        marginBottom: '55px',
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
      cancelIcon:{
        float:"right",
      },
      alert:{
        width:"100%",
        margin:"20px 0",
      },
      rightLink:{
        float: "right",
      }
}));

export default function SignUp(props) {
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const classes = useStyles();
  const methods = useForm();
  const { handleSubmit, register, errors } = methods;

  // React.useEffect(() => {        
  //   setIsLoading(false);
  // },[isLoading]);

  const onSubmit = (data, e) => {
    if(data.password){
      if(data.password === data.confirm_password){
        setIsLoading(true);
        setError("");
        setSuccess("");
        api.addCustomer(data).then((response)=> {
          setIsLoading(false);
          if(!response.data.success){
            setError('Account with that email address already exists.')
          }else{
            e.target.reset();
            setSuccess('Please verify your email address to complete registration.');       
          }
        })
      }else{
        setError("Password and Confirm Password do not match!");
      }
    }
  }
  const handleCloseError=()=>{
    setError("");
  }
  const handleCloseSuccess=()=>{
    setSuccess("");
}
  return (
    <ThemeProvider theme={whrepoTheme}>
      <RegisterSEO />
      <div>
      <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <IconButton aria-label="delete" component={RouterLink} to='/' className={classes.cancelIcon} title="Go to Home Page"><CancelIcon /></IconButton>
            <div className={classes.paper}>
                <div className={classes.logo}>
                <NavLink to="/"><img src="/images/whrepo-temp-logo.jpg" alt="Whrepo Logo" /></NavLink>
                </div>
                <Typography component="h1" variant="h5">
                  Welcome to Wheels Repository!
                </Typography>
                <Avatar className={classes.avatar}>
                <HowToRegSharpIcon />
                </Avatar>
                <Typography component="h2" variant="h6">Sign up to Browse &amp; Buy Vehicles</Typography>
                {error?<Alert className={classes.alert} variant="filled" severity="error" onClose={handleCloseError}>{error}</Alert>:""}
                        {success?<Alert className={classes.alert} variant="filled" severity="success" onClose={handleCloseSuccess}>{success}</Alert>:""}
                
                <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="firstName"
                      label="First Name"
                      type="text"
                      name="firstName"
                      autoComplete="fname"
                      variant="outlined"
                      fullWidth
                      autoFocus
                      error={!!errors.firstName}
                      inputRef={register({
                        required: 'Required',
                        pattern: {value:/^[a-zA-Z][a-zA-Z ]+$/,
                                  message:'Invalid data.'},
                        maxLength: {value:25,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.firstName && errors.firstName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.firstName.message}</Typography>:''}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="lastName"
                      label="Last Name"
                      type="text"
                      name="lastName"
                      autoComplete="lname"
                      variant="outlined"
                      fullWidth
                      error={!!errors.lastName}
                      inputRef={register({
                        required: 'Required',
                        pattern: {value:/^[a-zA-Z][a-zA-Z ]+$/,
                          message:'Invalid data.'},
                        maxLength: {value:30,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.lastName && errors.lastName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.lastName.message}</Typography>:''}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="email"
                      label="Email Address"
                      type="email"
                      name="email"
                      autoComplete="email"
                      variant="outlined"
                      fullWidth
                      error={!!errors.email}
                      inputRef={register({
                        required: 'Required',
                        pattern: {value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message:'Invalid Email Address.'},
                                  maxLength: {value:191,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.email && errors.email.message ? <Typography variant="caption" display="block" color="error" align="left">{errors.email.message}</Typography>:''}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <TextField
                      id="phone"
                      label="Phone Number"
                      type="tel"
                      name="phone"
                      autoComplete="phone"
                      variant="outlined"
                      fullWidth
                      error={!!errors.phone}
                      inputRef={register({
                        required: 'Required',
                        //pattern: {value:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                        pattern: {value:/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
                          message:'Invalid phone.'},
                          maxLength: {value:10,message:'Chars exceeded!'}
                        //maxLength: {value:10,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.phone && (errors.phone.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.phone.message}</Typography>:''}
                    {/* {errors.email && (errors.email.message)?errors.email.message:''} */}
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                      id="password"
                      label="Password"
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      variant="outlined"
                      fullWidth
                      error={!!errors.password}
                      inputRef={register({
                        required: 'Required',
                        minLength: {value:6,message:'Less than 6 chars.'}
                      })}
                      helperText={"Minimum 6 chars."}
                    />
                    {errors.password && <Typography variant="caption" display="block" color="error" align="left">Required</Typography>}
                    
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="confirm_password"
                        label="Confirm Password"
                        type="password"
                        name="confirm_password"
                        variant="outlined"
                        fullWidth
                        error={!!errors.confirm_password}
                        inputRef={register({
                          required: 'Required',
                          minLength: {value:6,message:'Less than 6 chars.'},
                          maxLength: {value:50,message:'Chars exceeded!'}

                        })}
                        helperText={"Minimum 6 chars."}
                      />
                      {errors.confirm_password && <Typography variant="caption" display="block" color="error" align="left">Required</Typography>}   
                  </Grid>
                </Grid>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                >
                    Sign Up
                </Button>
                <Grid container justify="flex-end">
                    <Grid item>
                        {isLoading?<CircularProgress color="secondary" size={30}/>:null}
                    </Grid>
                </Grid>
                <Grid container justify="center">
                <Grid item xs={12} sm={6}>
                  <Link color="secondary" href="/login" variant="body2">
                        Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container>
                <Grid item xs={12} sm={6}>
                    <Link color="secondary" href="/dealer-signup" variant="body2">
                      Dealer? Sign up here
                    </Link>
                 </Grid>
                 <Grid item xs={12} sm={6}>
                    <Link color="secondary" href="/sell-your-vehicle" variant="body2" className={classes.rightLink}>
                      Private Seller? Sign up here
                    </Link>
                  </Grid>
                 
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Divider />
                  <Typography variant="body2">By signing up you agree to our <Link color="secondary" href="/terms">Terms of Use</Link> and <Link color="secondary" href="/privacy">Privacy Policy</Link>.</Typography>
                  
                  </Grid>
                </Grid>
                </form>
            </div>
            
        
        </Grid>
        </Grid>
      </div>
      </ThemeProvider>
  );
}