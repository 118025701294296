import React from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import {BASE_URL, CURRENCY} from '../../utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from '../../assets/jss/components/teaserVehiclesStyle.js'
const useStyles = makeStyles(styles);

function RecentVehicles(props) {
    const classes = useStyles();
    return (
        <div className={classes.teaserVehicles}>
            <Typography component="h2" variant="h4" gutterBottom>Recent Vehicles</Typography>
            {!props.isLoading ? (
            <Grid container spacing={2}>
                
            {props.recentVehicles.map((vehicle, index)=>{
                //if(index < 6){
                    const conditionSlug = (vehicle.condition === "New")?"new-vehicle":"used-vehicle";
                    const conditionBadge = (vehicle.condition === "New")?classes.newBadge:classes.usedBadge;
                    const slug = conditionSlug + "/" + vehicle.title.replace(/ /g, "-")+"-"+vehicle.stock_number.toLowerCase();
                    const otherBadgeIDs = vehicle.badge_ids?vehicle.badge_ids.split(","):null;
                    const renderMainImage = (images => {
    
                        let path = "/images/wr-vehicle-dummy1.jpg";
                        if(images && images.length>0){
                            images.forEach((image)=> {
                                if(image.main_flag==="1"){
                                    var res = image.path.split(".");
                                    path = `${BASE_URL}/storage/whrepo/${res[0]}-150X150.${res[1]}`;
                                }
                            });
                        }    
                        return path;
                     });
                        return (
                        <Grid item xs={6} sm={4} md={2} key={index}>
                            <Card className={classes.card}>   
                            
                            <CardMedia
                                className={classes.media}
                                image={renderMainImage(vehicle.images)}
                                title={vehicle.title}
                                component={NavLink} 
                                to={`/${slug}/${vehicle.id}/`}
                            >
                                <div className={`${classes.badges} ${classes.badges1} ${conditionBadge}`}>
                                    <Chip label={vehicle.condition} size="small" />
                                </div>
                                <div className={classes.badges}>
                                {props.badges.map((badge)=>{
                                    const bgColor = (badge.bg_color_code)?badge.bg_color_code:"inherit";
                                    const txtColor = (badge.txt_color_code)?badge.txt_color_code:"inherit";
                                    const hasBadgeID = otherBadgeIDs?otherBadgeIDs.includes(`${badge.id}`):false;
                                    if(hasBadgeID){
                                        return(
                                        <Chip key={badge.id} label={badge.badge_name} size="small" style={{backgroundColor:bgColor,color:txtColor}}/>
                                        )
                                    }else{
                                        return null;
                                    }

                                })} 
                            </div> 
                            </CardMedia>
                            <CardHeader 
                                title={<NavLink to={`/${slug}/${vehicle.id}/`}>{vehicle.title}</NavLink>}
                                // subheader={(vehicle.selling_price)?<><Typography variant="subtitle1" className={classes.por}>{CURRENCY}{vehicle.selling_price}</Typography></>:<Typography variant="subtitle1" color="textSecondary" className={classes.por}>Price on request</Typography>}
                            subheader={
                                <div className="wr-price-figure">
                                                <del>{vehicle.price ?<span className="wr-price-amount"><span className="wr-currency-symbol">{CURRENCY}</span>{vehicle.price}</span> : null}</del>
                                                <ins>{vehicle.selling_price ? <span className="wr-price-amount"><span className="wr-currency-symbol">{CURRENCY}</span>{vehicle.selling_price}</span> : null}</ins>
                                                {vehicle.price || vehicle.selling_price ?<div className="wr-tax-info">Plus Sales Tax</div>:<div className="wr-tax-info">Contact for Price</div>}
                                            </div>
                            }
                            >
                                
                            </CardHeader>
                           
                            </Card> 
                        </Grid>
                        
                        )
                //}
            })} 
            </Grid>
            ) : (
                <Grid item xs={12}>
                    <CircularProgress color="secondary" />
                    <p>Loading...</p>
                </Grid>
              )}
        </div>
    )
}
export default RecentVehicles;