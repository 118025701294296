import React from 'react'
import PrivateOwnerSignUpSEO from '../../components/SEO/PrivateOwnerSignUp'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import {BASE_URL} from '../../utils'

import Link from '@material-ui/core/Link'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import HowToRegSharpIcon from '@material-ui/icons/HowToRegSharp'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Alert from '@material-ui/lab/Alert'
import { CircularProgress } from '@material-ui/core'

import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

import useForm from "react-hook-form"
import * as api from '../../api/customerApi'


const useStyles = makeStyles(theme => ({
   root: {
    marginTop:"100px",
    minHeight: "calc(100vh - 341px)",
    "& .MuiDivider-root":{
      margin:"20px 0",
    }
   },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  rightLink:{
    float: "right",
  }
}));

export default function PrivateOwnerSignUp(props) {
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const classes = useStyles();
  const methods = useForm();
  const [isLoading, setIsLoading] = React.useState(false);
  const handleClose = () => {
    setError("");
    setSuccess("");
  }
  const { handleSubmit, register, errors, reset } = methods;
  const onSubmit = (data) => {
    if(data.password){
      if(data.password === data.confirm_password){
        setIsLoading(true);
        setError("");
        setSuccess("");

        api.privateOwnerSignUp(data).then((response)=> {
          setIsLoading(false);
          if(!response.data.success){
            setError(response.data.errormsg);   
          }else{
            setSuccess('Registration successful, Please verify your email');
            reset();
          }
        })
      }else{
        setError("Password and Confirm Password do not match!");
      }
    }
   
  
  }
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <ThemeProvider theme={whrepoTheme}>
      <PrivateOwnerSignUpSEO />
    <Header user={props.user} isAuthenticated={props.isAuthenticated}/>
    <Container component="main" maxWidth="sm" className={classes.root}>
      <div className={classes.paper}>
        <Card>
        <CardContent>
          <CardHeader
           avatar={<Avatar aria-label="lock" className={classes.avatar}><HowToRegSharpIcon /></Avatar>}
           title={<Typography component="h1" variant="h6">Private Owner Sign up to Post &amp; Sell Vehicle</Typography>}
          >
          </CardHeader>
        {error?<Alert className={classes.alert} variant="filled" severity="error" onClose={handleClose}>{error}</Alert>:""}
        {success?<Alert className={classes.alert} variant="filled" severity="success" onClose={handleClose}>{success}</Alert>:""}
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
            <TextField
                      id="firstName"
                      label="First Name"
                      type="text"
                      name="firstName"
                      autoComplete="fname"
                      variant="outlined"
                      fullWidth
                      error={!!errors.firstName}
                      inputRef={register({
                        required: 'Required',
                        pattern: {value:/^[a-zA-Z][a-zA-Z ]+$/,
                          message:'Invalid data.'},
                        maxLength: {value:30,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.firstName && errors.firstName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.firstName.message}</Typography>:''}
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                      id="lastName"
                      label="Last Name"
                      type="text"
                      name="lastName"
                      autoComplete="lname"
                      variant="outlined"
                      fullWidth
                      error={!!errors.lastName}
                      inputRef={register({
                        required: 'Required',
                        pattern: {value:/^[a-zA-Z][a-zA-Z ]+$/,
                          message:'Invalid data.'},
                        maxLength: {value:25,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.lastName && errors.lastName.message? <Typography variant="caption" display="block" color="error" align="left">{errors.lastName.message}</Typography>:''}
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                      id="email"
                      label="Email Address"
                      type="email"
                      name="email"
                      autoComplete="email"
                      variant="outlined"
                      fullWidth
                      error={!!errors.email}
                      inputRef={register({
                        required: 'Required',
                        pattern: {value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                  message:'Invalid Email Address.'},
                                  maxLength: {value:191,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.email && errors.email.message ? <Typography variant="caption" display="block" color="error" align="left">{errors.email.message}</Typography>:''}
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                      id="phone"
                      label="Phone Number"
                      type="tel"
                      name="phone"
                      autoComplete="phone"
                      variant="outlined"
                      fullWidth
                      error={!!errors.phone}
                      inputRef={register({
                        required: 'Required',
                        maxLength: {value:15,message:'Chars exceeded!'}
                      })}
                    />
                    {errors.phone && (errors.phone.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.phone.message}</Typography>:''}
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                  id="password"
                  label="Password"
                  type="password"
                  name="password"
                  variant="outlined"
                  fullWidth
                  error={!!errors.password}
                  inputRef={register({
                    required: 'Required',
                    minLength: {value:6,message:'Less than 6 chars.'},
                    maxLength: {value:50,message:'Chars exceeded!'}
                  })}
                  helperText={"Minimum 6 chars."}
                />
                {errors.password && <Typography variant="caption" display="block" color="error" align="left">Required</Typography>}   
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                  id="confirm_password"
                  label="Confirm Password"
                  type="password"
                  name="confirm_password"
                  variant="outlined"
                  fullWidth
                  error={!!errors.confirm_password}
                  inputRef={register({
                    required: 'Required',
                    minLength: {value:6,message:'Less than 6 chars.'},
                    maxLength: {value:50,message:'Chars exceeded!'}

                  })}
                  helperText={"Minimum 6 chars."}
                />
                {errors.confirm_password && <Typography variant="caption" display="block" color="error" align="left">Required</Typography>}   
            </Grid>
            
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
                    <Grid item>
                        {isLoading?<CircularProgress color="secondary" size={30}/>:null}
                    </Grid>
                </Grid>
          <Grid container>
          <Grid item xs={12} sm={6}>
              <Link color="secondary" href="/dealer-signup" variant="body2">
                Dealer? Sign up here
              </Link>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Link color="secondary" href={BASE_URL} variant="body2" className={classes.rightLink}>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Divider />
            <Typography variant="body2">By signing up you agree to our <Link color="secondary" href="/terms">Terms of Use</Link> and <Link color="secondary" href="/privacy">Privacy Policy</Link>.</Typography>
            
            </Grid>
          </Grid>
          
        </form>
        </CardContent>
        </Card>
      </div>
    </Container>
    <Footer/>
    </ThemeProvider>
  );
}