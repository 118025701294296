import React from 'react'
import { Helmet } from 'react-helmet'

export default function PrivateOwnerSignUpSEO() {
  return (
    <>
        <Helmet>
          <title>Sell your Vehicle &#8226; Wheels Repository  </title>
          <meta name="description" content="Sell your Vehicle - whrepo.com" />
          <link rel="canonical" href="https://whrepo.com/sell-your-vehicle" />
        </Helmet>
    </>
  )
}

