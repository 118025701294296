import React from 'react'
import { NavLink, useParams, useLocation, Link as RouterLink } from "react-router-dom"

import ResetPasswordSEO from '../../components/SEO/ResetPassword'
import * as api from '../../api/customerApi'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import CancelIcon from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import { makeStyles, ThemeProvider} from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

import useForm from "react-hook-form";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/flat-bg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(5, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logo:{
    marginBottom: '75px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cancelIcon:{
    float:"right",
  },
  alert:{
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export default function ResetPassword() {
  const classes = useStyles();
  const [error, setError] = React.useState("");
  const [success,setSuccess]=React.useState(false);
  const methods = useForm();
  const { id} = useParams();
  let location = useLocation();
  let search = location.search.split("?");
  let token = null;
  if(search){
      token = search[1];
  }
  const { handleSubmit, register, errors } = methods;
  const onSubmit = (data, e) => {
      if(data.password){
          if(data.password === data.c_password){
            api.resetPassword(id,token,data).then((response)=> {
                if(response.data.success){
                  e.target.reset();
                  setSuccess(true);
                }else{
                  setError(response.data.error);
                }
            })

          }else{
            setError("Password and Confirm Password do not match!");
          }

      }else{
          //handled by required
      }
  }

  const handleClose = () => {
    setError("");
    setSuccess(false);
  }

  return (
    <ThemeProvider theme={whrepoTheme}>
      <ResetPasswordSEO />
      <div>
      
    <Grid container component="main" className={classes.root}>

      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <IconButton aria-label="delete" component={RouterLink} to='/' className={classes.cancelIcon} title="Go to Home Page"><CancelIcon /></IconButton>
        <div className={classes.paper}>
        <div class={classes.logo}>
            <NavLink to="/"><img src="/images/whrepo-temp-logo.jpg" alt="Whrepo Logo" /></NavLink>
          </div>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
        {error?<Alert className={classes.alert} variant="filled" severity="error" onClose={handleClose}>{error}</Alert>:""}
        {success?<Alert className={classes.alert} variant="filled" severity="success" onClose={handleClose}>Password has been changed successfully.</Alert>:""}
        {success?
        <Grid container>
          <Grid item xs>
            <Link color="secondary" href="/login" variant="body2">
              Login here
            </Link>
          </Grid>
        </Grid>
        :null}

          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                      id="password"
                      margin="normal"
                      label="Password"
                      type="password"
                      name="password"
                      autoComplete="current-password"
                      variant="outlined"
                      fullWidth
                      error={!!errors.password}
                      inputRef={register({
                        required: 'Required'
                      })}
                    />
                    {errors.password && "Required"?<Typography variant="caption" display="block" color="error" align="left">{errors.password && "Required"}</Typography>:""}

                    <TextField
                      id="c_password"
                      margin="normal"
                      label="Confirm Password"
                      type="password"
                      name="c_password"
                      variant="outlined"
                      fullWidth
                      error={!!errors.c_password}
                      inputRef={register({
                        required: 'Required'
                      })}
                    />
                    {errors.c_password && "Required"?<Typography variant="caption" display="block" color="error" align="left">{errors.c_password && "Required"}</Typography>:""}
            
            
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Reset
            </Button>
            
            <Grid container>
              <Grid item xs>
                <Link color="secondary" href="/" variant="body2">
                  Cancel the reset password?
                </Link>
              </Grid>
              
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
    </div>
    </ThemeProvider>
  );
}