import React from 'react'
import { Helmet } from 'react-helmet'

export default function ContactSEO() {
  return (
    <>
        <Helmet>
            <title>Contact &#8226; Wheels Repository</title>
            <meta name="description" content="Contact - whrepo.com" />
            <link rel="canonical" href="https://whrepo.com/contact" />
        </Helmet>
    </>
  )
}

