import React from 'react'
import { NavLink, Link as RouterLink} from "react-router-dom"

import ForgotPasswordSEO from '../../components/SEO/ForgotPassword'
import * as api from '../../api/customerApi'

import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import CancelIcon from '@material-ui/icons/Cancel'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import { CircularProgress } from '@material-ui/core'

import { makeStyles, ThemeProvider} from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

import useForm from "react-hook-form"

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/images/flat-bg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(5, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  logo:{
    marginBottom: '75px',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  cancelIcon:{
    float:"right",
  },
  alert:{
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [error, setError] = React.useState("");
  const [success,setSuccess]=React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const methods = useForm();
  const { handleSubmit, register, errors } = methods;
  const onSubmit = (data, e) => {
    setError("");
    setSuccess("");
    setIsLoading(true);
    api.forgotPassword(data).then((response)=> {
      setIsLoading(false);
      if(response.data.success){
        e.target.reset();
        setSuccess(true);
      }else{
        setError(response.data.error);
      }
    })
  }

  const handleClose = () => {
    setError("");
    setSuccess(false);
  }

  return (
    <ThemeProvider theme={whrepoTheme}>
      <ForgotPasswordSEO />
    <div>
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <IconButton aria-label="delete" component={RouterLink} to='/' className={classes.cancelIcon} title="Go to Home Page"><CancelIcon /></IconButton>
        <div className={classes.paper}>
        <div class={classes.logo}>
            <NavLink to="/"><img src="/images/whrepo-temp-logo.jpg" alt="Whrepo Logo" /></NavLink>
          </div>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          {error?<Alert className={classes.alert} variant="filled" severity="error" onClose={handleClose}>{error}</Alert>:""}
        {success?<Alert className={classes.alert} variant="filled" severity="success" onClose={handleClose}>Please visit your inbox to reset password.</Alert>:""}

          
          <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
              id="email"
              label="Email Address"
              type="email"
              name="email"
              autoComplete="email"
              variant="outlined"
              margin="normal"
              fullWidth
              error={!!errors.email}
              inputRef={register({
                required: 'Required',
                pattern: {value:/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message:'Invalid email address.'},
                max: 191
              })}
            />
            {errors.email && (errors.email.message)?<Typography variant="caption" display="block" color="error" align="left">{errors.email.message}</Typography>:""}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
            <Grid container justify="flex-end">
                  <Grid item>
                      {isLoading===true?<CircularProgress color="secondary" size={30}/>:null}
                  </Grid>
              </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
    </div>
    </ThemeProvider>
  );
}