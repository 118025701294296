import React from 'react'
import { Helmet } from 'react-helmet'

export default function HomeSEO() {
  return (
    <>
        <Helmet>
            <title>Wheels Repository &#8226; Used Vehicles &#8226; New Vehicles &#8226; Buy &amp; Sell Vehicles &#8226; Nepal </title>
            <meta name="description" content="New and Used vehicles online repository in Nepal." />
            <link rel="canonical" href="https://whrepo.com" />
        </Helmet>
    </>
  )
}

