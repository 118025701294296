import React from 'react'
import { Helmet } from 'react-helmet'

export default function AboutSEO() {
  return (
    <>
        <Helmet>
            <title>About &#8226; Wheels Repository  </title>
            <meta name="description" content="New and Used vehicles online repository in Nepal." />
            <link rel="canonical" href="https://whrepo.com/about" />
        </Helmet>
    </>
  )
}

