import React from 'react'
import { Helmet } from 'react-helmet'

export default function DealerListingSEO() {
  return (
    <>
        <Helmet>
            <title>Dealers Listing &#8226; Wheels Repository  </title>
            <meta name="description" content="New and Used vehicles dealers in Nepal." />
            <link rel="canonical" href="https://whrepo.com/dealers" />
        </Helmet>
    </>
  )
}

