import React from 'react'

import ActivationSEO from '../../components/SEO/Activation'
import Header from '../../components/Header/Header'
import SimpleBreadcrumbs from '../../components/Breadcrumbs/Breadcrumbs'
import Footer from '../../components/Footer/Footer'
import * as api from '../../api/customerApi'

import Alert from '@material-ui/lab/Alert'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import whrepoTheme from '../../assets/jss/components/whrepoMuiTheme'

import queryString from 'query-string'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        "& .MuiDivider-root":{
          margin:"20px 0",
        }
      },
      main:{
        marginTop:100,
      },
      image: {
        backgroundImage: 'url(/images/flat-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundColor:
          theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      },
      paper: {
        margin: theme.spacing(5, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
      },
      form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
      },
      logo:{
        marginBottom: '55px',
      },
      submit: {
        margin: theme.spacing(3, 0, 2),
      },
      cancelIcon:{
        float:"right",
      },
}));



export default function ActivationPage(props) {
    const [isLoading, setIsLoading] = React.useState(true);
    const classes = useStyles();
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const handleCloseError=()=>{
        setError("");
      }
      const handleCloseSuccess=()=>{
        setSuccess("");
    }

    React.useEffect(() => {  
        const params = queryString.parse(props.location.search);
        var delay = 5000; 
        api.verifyCustomer(params.id, params.token).then((response)=> {
            setIsLoading(false);
            if(!response.data.success){
              
              if(response.data.r === "y")  {
                setTimeout(()=>{
                    window.location = "/login";
                   },delay);
              }else{
                setError(response.data.error); 
              }   
            }else{
              setSuccess('Email verified successfully. Redirecting to log in page....'); 
              setTimeout(()=>{
                window.location = "/login";
               },delay);
                 
            }
        })
    },[isLoading, props.location.search]);     
  
    return(
          <>
            <ThemeProvider theme={whrepoTheme}>
              <ActivationSEO />
              <Header user={props.user} isAuthenticated={props.isAuthenticated}/>
              <main className={classes.main}>
                <Container>
                  <SimpleBreadcrumbs />
                  {error?<Alert className={classes.alert} variant="filled" severity="error" onClose={handleCloseError}>{error}</Alert>:""}
                  {success?<Alert className={classes.alert} variant="filled" severity="success" onClose={handleCloseSuccess}>{success}</Alert>:""}
                  <Grid item xs={12}>
                    <p>Please wait...</p>
                    <CircularProgress color="secondary" size={30}/>
                  </Grid>
                </Container>
              </main>
              <Footer />
            </ThemeProvider>
        </>
        )
}
