import React from 'react'
import { Helmet } from 'react-helmet'

export default function ActivationSEO() {
  return (
    <>
        <Helmet>
            <title>Activation &#8226; Wheels Repository</title>
            <meta name="description" content="Activation - whrepo.com" />
            <link rel="canonical" href="https://whrepo.com/register" />
        </Helmet>
    </>
  )
}

