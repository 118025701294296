import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import LinkIcon from '@material-ui/icons/Link';
import StoreIcon from '@material-ui/icons/Store';
import {BACKEND_URL} from '../../../utils';

const useStyles = makeStyles({
    root: {
      display: "flex",
      minWidth: 275,
      justifyContent: "space-between",
      marginBottom: 16,
      "& .MuiCardContent-root:last-child":{
        paddingBottom:16,
      }
    },
    
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
      padding: 10,
    },
    title: {
      fontSize: 16,
      lineHeight: 1,
    },
    list:{
      lineHeight: 1,
      "& .MuiListItemText-root":{
        marginTop:0,
        marginBottom:0,
        color:"rgba(0, 0, 0, 0.54)",
      },
      "& .MuiListItemIcon-root":{
        minWidth: 35
      }
    },
    cover: {
      width: 151,
      backgroundSize: "contain",
      marginRight:10,
      //border:"1px solid #e5e5e5",
    },

  });

const DealerItem = (props) => {
    const classes = useStyles();
    const { dealer } = props;
    const dealerName = dealer.name?dealer.name.toLowerCase().replace(" ","-"):"";
    let dealerAddress = dealer.address?dealer.address:"";
    dealerAddress += dealer.address2||dealer.address2!==null?", "+dealer.address2:"";
    dealerAddress += dealer.city?", "+dealer.city:"";
    dealerAddress += dealer.province?", "+dealer.province:"";
    dealerAddress += dealer.postal_code?", "+dealer.postal_code:"";
    //dealerAddress += dealer.country?", "+dealer.country:"";
    return(
        
        <Card className={classes.root}>
          <div className={classes.details}>
            <CardContent className={classes.content}>
            {dealer.name?<Typography variant="h6" component="h2" className={classes.title}>{dealer.name}</Typography>:null}
                <List dense={true} className={classes.list}>
                {dealer.location_name?
                    <ListItem disableGutters>
                    <ListItemIcon>
                      <StoreIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={dealer.location_name}
                    />
                    </ListItem>:""
                  }
                  {dealerAddress?
                    <ListItem disableGutters>
                    <ListItemIcon>
                      <LocationOnIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={dealerAddress}
                    />
                    </ListItem>:""
                  }
                  {dealer.contact_no?
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <PhoneIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Link color="textSecondary" href={"tel:"+ dealer.contact_no}>{dealer.contact_no}</Link>}
                    />
                  </ListItem>:""
                  }
                  { dealer.website && dealer.website!=='null'?
                    <ListItem disableGutters>
                    <ListItemIcon>
                      <LinkIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Link color="textSecondary" href={dealer.website} target="_blank">{dealer.website}</Link>}
                    />
                  </ListItem>:""

                  }
                  
                </List>
                {dealer.count>0?
                  <Button onClick={()=>{window.location=`dealer/${dealerName}/${dealer.id}/${dealer.loc_id}/`}} variant="contained" size="small">View {dealer.count} {dealer.count>1?"Vehicles":"Vehicle"}</Button>
                  : ""}
            </CardContent>
            </div>
            {dealer.logo?
            <CardMedia
              className={classes.cover}
              image={BACKEND_URL + "/storage/whrepo/logos/" + dealer.logo}
              title={dealer.name}
            />:null}
        </Card>
        
    )
};

export default DealerItem;