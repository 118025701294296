import React from 'react'
import { Helmet } from 'react-helmet'

export default function NewVehiclesSEO() {
  return (
    <>
        <Helmet>
            <title>New Vehicles &#8226; Wheels Repository  </title>
            <meta name="description" content="Browse New Vehicles" />
            <link rel="canonical" href="https://whrepo.com/new-vehicles" />
        </Helmet>
    </>
  )
}

